<template>
  <b-card
    title="Edit account"
  >

    <b-form @submit.prevent>
      <b-row>

        <b-col cols="6">
          <b-form-group
            label="Name(Label)"
            label-for="v-name"
          >
            <b-form-input
              id="v-name"
              v-model="account.name"
              placeholder="Name"
              required
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Account Id"
            label-for="v-accountId"
          >
            <b-form-input
              id="v-accountId"
              v-model="account.accountId"
              placeholder="accountId"
              required
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">

          <b-form-group
            label="Run at(UTC time)"
            label-for="v-runAt"
          >
            <b-form-input
              id="v-runAt"
              v-model="account.runAt"
              placeholder="Run at"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Status"
            label-for="v-status"
            required
          >
            <b-form-select
              v-model="account.active"
              :options="statusOptions"
            />

          </b-form-group>
        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Ads Default DataSet"
            label-for="v-bigQueryDataSet"
          >
            <b-form-input
              id="v-bigQueryDataSet"
              v-model="account.bigQueryDataSet"
              placeholder="BigQuery Ads Default DataSet"
              required
            />
          </b-form-group>

        </b-col>


        <b-col cols="12">
          <h5> <feather-icon icon="DatabaseIcon" /> Sponsored Products Tables </h5>
        </b-col>

        <b-col cols="12">

            <b-form-group
              label="BigQuery Sponsored Product DataSet"
              label-for="v-bigQuerySpDataSet"
            >
              <b-form-input
                id="v-bigQueryDataSet"
                v-model="account.bigQuerySpDataSet"
                placeholder="BigQuery Sponsored Product DataSet"
                required
              />
            </b-form-group>

          </b-col>


        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spCampaigns Type Table"
            label-for="v-bigQueryCampaignsTable"
          >
            <b-form-input
              id="v-bigQueryCampaignsTable"
              v-model="account.bigQueryTable_spCampaigns"
              placeholder="BigQuery Sponsored Product spCampaigns"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spSearchTerm Table"
            label-for="v-bigQueryProductAdsTable"
          >
            <b-form-input
              id="v-bigQueryspSearchTermTable"
              v-model="account.bigQueryTable_spSearchTerm"
              placeholder="BigQuery Sponsored Product spSearchTerm Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spTargeting Type Table"
            label-for="v-bigQueryspTargeting"
          >
            <b-form-input
              id="v-bigQueryspTargetingTable"
              v-model="account.bigQueryTable_spTargeting"
              placeholder="BigQuery Sponsored Product spTargeting Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spAdvertisedProduct Type Table"
            label-for="v-bigQueryspAdvertisedProductTable"
          >
            <b-form-input
              id="v-bigQueryKeywordsTable"
              v-model="account.bigQueryTable_spAdvertisedProduct"
              placeholder="BigQuery Sponsored Product spAdvertisedProduct Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spPurchasedProduct Type Table"
            label-for="v-bigQueryspPurchasedProductTable"
          >
            <b-form-input
              id="v-bigQueryspPurchasedProductTable"
              v-model="account.bigQueryTable_spPurchasedProduct"
              placeholder="BigQuery Sponsored Product spPurchasedProduct Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Product spGrossAndInvalids Type Table"
            label-for="v-bigQueryspGrossAndInvalidsTable"
          >
            <b-form-input
              id="v-bigQueryspGrossAndInvalidsTable"
              v-model="account.bigQueryTable_spGrossAndInvalids"
              placeholder="BigQuery Sponsored Product spGrossAndInvalids Table"
              required
            />
          </b-form-group>

          </b-col>

        <b-col cols="12">
          <h5> <feather-icon icon="DatabaseIcon" /> Sponsored Display Tables </h5>
        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Display Asins Type Table"
            label-for="v-bigQueryAsinsTable"
          >
            <b-form-input
              id="v-bigQueryAsinsTable"
              v-model="account.bigQueryTable_sd_asins"
              placeholder="BigQuery Sponsored Display Asins Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Display Campaigns Type Table"
            label-for="v-bigQueryCampaignsTable"
          >
            <b-form-input
              id="v-bigQueryCampaignsTable"
              v-model="account.bigQueryTable_sd_campaigns"
              placeholder="BigQuery Sponsored Display Campaigns Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Display ProductAds Type Table"
            label-for="v-bigQueryProductAdsTable"
          >
            <b-form-input
              id="v-bigQueryProductAdsTable"
              v-model="account.bigQueryTable_sd_productAds"
              placeholder="BigQuery Sponsored Display ProductAds Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Display AdGroups Type Table"
            label-for="v-bigQueryAdGroupsTable"
          >
            <b-form-input
              id="v-bigQueryAdGroupsTable"
              v-model="account.bigQueryTable_sd_adGroups"
              placeholder="BigQuery Sponsored Display AdGroups Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Display Targets Type Table"
            label-for="v-bigQueryTargetsTable"
          >
            <b-form-input
              id="v-bigQueryTargetsTable"
              v-model="account.bigQueryTable_sd_targets"
              placeholder="BigQuery Sponsored Display Targets Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="12">
          <h5>  <feather-icon icon="DatabaseIcon" /> Sponsored Brands Tables </h5>
        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Brands Campaign Type Table"
            label-for="v-bigQueryHsaCampaignTable"
          >
            <b-form-input
              id="v-bigQueryHsaCampaignTable"
              v-model="account.bigQueryTable_hsa_campaign"
              placeholder="BigQuery Sponsored Brands Campaign Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Brands AdGroups Type Table"
            label-for="v-bigQueryHsaAdGroupsTable"
          >
            <b-form-input
              id="v-bigQueryHsaAdGroupsTable"
              v-model="account.bigQueryTable_hsa_adGroup"
              placeholder="BigQuery Sponsored Brands AdGroups Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Brands Keywords Type Table"
            label-for="v-bigQueryHsaKeywordsTable"
          >
            <b-form-input
              id="v-bigQueryHsaKeywordsTable"
              v-model="account.bigQueryTable_hsa_keyword"
              placeholder="BigQuery Sponsored Brands Keywords Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Brands Targets Type Table"
            label-for="v-bigQueryHsaTargetsTable"
          >
            <b-form-input
              id="v-bigQueryHsaTargetsTable"
              v-model="account.bigQueryTable_hsa_targets"
              placeholder="BigQuery Sponsored Brands Targets Table"
              required
            />
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <b-form-group
            label="BigQuery Sponsored Brands Ads Type Table"
            label-for="v-bigQueryHsaAdsTable"
          >
            <b-form-input
              id="v-bigQueryHsaAdsTable"
              v-model="account.bigQueryTable_hsa_ad"
              placeholder="BigQuery Sponsored Brands Ads Table"
              required
            />
          </b-form-group>

        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="updateAccount"
          >
            Save
          </b-button>

          <router-link :to="{name:'amz-ads-accounts'}">

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Back
            </b-button>

          </router-link>

        </b-col>
      </b-row>
    </b-form>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      account: {},
      channelSelected: null,
      statusOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Disabled' },
      ],
    }
  },
  mounted() {
    this.loadAccount(this.$route.params.accountId)
  },
  methods: {

    loadAccount(accountId) {
      this.$store.dispatch('amzAds/get', accountId)
        .then(result => {
          this.account = result.data
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading Account', 'danger')
        })
    },

    updateAccount() {
      const data = this.account

      this.$store.dispatch('amzAds/update', this.account)
        .then(result => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
